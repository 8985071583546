<template>
    <div class="app flex-row align-items-center">

        <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">


            <div v-if="show == true" id="principal" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <div class="col-md-6">
                        <h4 class="demo-title"><strong><span style="cursor: pointer" @click="VolverPanel">Panel Administrativo</span> > <span @click="IrEventos" style="cursor: pointer"> Eventos</span> > {{evento.title}} </strong></h4>
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                </mdb-row>

                <hr />

                <div style="padding: 10px"></div>

                <section>

                    <mdb-tabs
                            :active="0"
                            default
                            :links="[
          { text: 'Estado' },
          { text: 'Grabaciones' },
          { text: 'Retransmisiones'},
          { text: 'Editar'}]"
                            :transition-duration="0.5"
                            transition-style="linear"
                    >

                        <template :slot="'Estado'" style="width: 100%">
                            <b-row>

                            </b-row>
                        </template>
                        <template :slot="'Grabaciones'" style="width: 100%">
                            <b-row>
                                <div class="col-md-6">
                                    <b-table striped hove selectable select-mode="single" @row-clicked="SeleccionarGrabacion" :fields="grabaciones_campos" :items="evento.grabaciones">
                                    </b-table>
                                </div>
                                <div class="col-md-6">

                                    <div v-show="video_elemento_seleccionado =! null || !video_elemento_seleccionado">
                                        <b-row style="padding: 30px" v-show="url_descarga.length >0">
                                            <mdb-card>
                                                <mdb-card-body>
                                                    <mdb-card-title v-if="grabacion_selected">{{grabacion_selected.nombre_fichero}}</mdb-card-title>
                                                    <mdb-card-title tag="h6" v-if="video_elemento_seleccionado == null || !video_elemento_seleccionado" sub class="mb-2 text-muted">  NO SUBIDO AÚN </mdb-card-title>
                                                    <mdb-card-title tag="h6" v-if=" grabacion_selected && video_elemento_seleccionado != null " sub class="mb-2 text-muted">  SUBIDO COMO VÍDEO : {{grabacion_selected.video_id}}</mdb-card-title>
                                                    <mdb-card-text>Todas las retransmisiones finalizadas dentro de la horas horas programadas de un evento se listan en la tabla de la izquierda. Como el formato de grabación es FLV no podemos reproducirlo aquí y se debe revisar manualmente descargándolo desde aquí. Una vez hecho esto, se puede subir como un vídeo a Agustinos.TV </mdb-card-text>

                                                    <a ref="enlace_descarga" v-bind:href="'#'" @click="DescargarGrabacion"><mdb-badge color="indigo"  style="padding: 10px;">Descargar Grabación</mdb-badge> </a>
                                                    <a ref="enlace_descarga" v-if="video_elemento_seleccionado == null || !video_elemento_seleccionado" v-bind:href="'#'" @click="SubirGrabacion"><mdb-badge color="primary" style="padding: 10px;margin-left: 30px">Subir Vídeo</mdb-badge> </a>
                                                    <a ref="enlace_descarga" v-else v-bind:href="'#'"><mdb-badge color="success" style="padding: 10px;margin-left: 30px" @click="IrVideo">Ver Vídeo</mdb-badge> </a>
                                                    <a ref="enlace_descarga" v-bind:href="'#'"><mdb-badge color="danger" style="float:right;padding: 10px;">Eliminar Grabación</mdb-badge> </a>
                                                </mdb-card-body>
                                            </mdb-card>
                                        </b-row>
                                    </div>
                                    <div>

                                    </div>

                                </div>
                            </b-row>
                        </template>
                        <template :slot="'Retransmisiones'" style="width: 100%">
                            <b-table striped hove :fields="retransmisiones_campos" :items="evento.retransmisiones">
                            </b-table>
                        </template>
                        <template :slot="'Editar'" style="width: 100%">
                            <b-row style="padding: 20px">
                                <div class="col-md-3">
                                    <label for="Nombre de la Delegación" style="padding-top:15px">Nombre de el evento</label>
                                    <input type="text" id="username"  v-model="evento.title" class="form-control">
                                </div>
                                <div class="col-md-7">
                                    <label for="Web de la delegación" style="padding-top:15px">Descripción de el evento</label>
                                    <input type="text" id="username"  v-model="evento.descripcion" class="form-control">
                                </div>
                                <div class="col-md-2">
                                    <label for="" style="padding-top:15px">Delegación</label>
                                    <select v-model="evento.delegacion_id" :class="'form-control'">
                                        <option v-for="(item) in delegaciones" :value="item.id">
                                            {{item.nombre}}
                                        </option>
                                    </select>
                                </div>

                            </b-row>
                            <b-row style="padding: 20px">
                                <div class="col-md-3">
                                    <label for="Web de la delegación" style="padding-top:15px">Fecha de  inicio</label>
                                    <datepicker :inline="true" v-model="inicio_dia" :format="customFormatter"></datepicker>
                                </div>
                                <div class="col-md-2">
                                    <b-row>
                                        <label for="Web de la delegación" style="padding-top:15px">Hora de inicio</label>
                                    </b-row>
                                    <b-row>
                                        <vue-timepicker v-model="inicio_hora" format="HH:mm"></vue-timepicker>
                                    </b-row>

                                </div>
                                <div class="col-md-3">
                                    <label for="Web de la delegación" style="padding-top:15px">Fecha de  finalización</label>
                                    <datepicker :inline="true" v-model="final_dia" :format="customFormatter"></datepicker>
                                </div>
                                <div class="col-md-2">
                                    <b-row>
                                        <label for="Web de la delegación" style="padding-top:15px">Hora de finalización</label>
                                    </b-row>
                                    <b-row>
                                        <vue-timepicker v-model="final_hora" format="HH:mm"></vue-timepicker>
                                    </b-row>

                                </div>
                                    <div class="col-md-2">
                                        <label for="" style="padding-top:15px">Canal</label>
                                        <select v-model="evento.canal_id" :class="'form-control'">
                                            <option v-for="(item) in canales" :value="item.id">
                                                {{item.nombre}}
                                            </option>
                                        </select>
                                    </div>






                            </b-row>
                            <b-row style="width: 100%">
                                <div style="padding: 10px"></div>
                                <hr />

                                <div style="padding: 10px"></div>
                                <footer style="width: 100%;">
                                    <mdb-btn style="float:left;margin-left:35px" color="primary" @click="UpdateEvento()" size="lg">Actualizar</mdb-btn>
                                    <mdb-btn style="float:right" color="danger" @click="DeleteEvento" size="lg">Eliminar</mdb-btn>
                                </footer>

                                <div style="padding: 10px"></div>
                            </b-row>
                        </template>
                    </mdb-tabs>



                </section>






            </div>

        </b-row>

        <vue-cookie-accept-decline
                :ref="'myPanel1'"
                :elementId="'myPanel1'"
                :debug="false"
                :position="'bottom-left'"
                :type="'floating'"
                :disableDecline="true"
                :transitionName="'slideFromBottom'"
                :showPostponeButton="false"
                @status="cookieStatus"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline">

            <!-- Optional -->
            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
            </div>


            <!-- Optional -->
            <div slot="acceptContent">
                ACEPTAR
            </div>
        </vue-cookie-accept-decline>
    </div>



</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbBadge,
        mdbCardText,
        mdbCol,
        mdbTabs,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Datepicker from 'vuejs-datepicker';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import moment from 'moment'
    import { videoPlayer } from 'vue-video-player';

    export default {
        name: 'Delegación Detalle',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbTabs,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbCol,
            mdbBtn,
            mdbCardGroup,
            mdbBadge,
            vueDropzone: vue2Dropzone,
            Datepicker,
            VueTimepicker,
        },
        data() {
            return {
                show: false,
                token : localStorage.getItem('token'),
                seleccionados: [],
                activo: false,
                estados : ['ON','OFF'],
                fields_usuarios: [
                    {key: 'id', label: 'Id', sortable: true },
                    {key: 'username', label: 'Nombre Usuario', sortable: true},
                    {key: 'email', label: 'Email', sortable: true},
                    {key: 'role', label: 'Rol', sortable: true},
                    {key: 'active', label: 'Activado', sortable: true},
                    {key: 'created_at', label: 'Fecha creación', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')} },
                ],
                retransmisiones_campos: [
                    {key: 'id', label: 'Id', sortable: true },
                    {key: 'estado', label: 'Estado', sortable: true},
                    {key: 'created_at', label: 'Fecha Creación', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')}},
                    {key: 'updated_at', label: 'Fecha Actualización', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')}},
                ],
                grabaciones_campos: [
                    {key: 'id', label: 'Id', sortable: true },
                    {key: 'nombre_fichero', label: 'Nombre del Fichero', sortable: true},
                    {key: 'video_id', label: 'Vídeo Asignado', sortable: true},
                    {key: 'created_at', label: 'Fecha Creación', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')}},
                    {key: 'updated_at', label: 'Fecha Actualización', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')}},
                ],

                inicio_dia : '',
                final_dia : '',
                inicio_hora: '',
                final_hora: '',
                evento : {
                    title : '',
                    descripcion : '',
                    inicio : '',
                    final : '',
                    delegacion_id : '',
                    views : '',
                    likes : '',
                    max_online : '',
                    canal_id : '',
                    estado : '',
                },
                esta_en_vertical: false,
                pagina_actual : 1,
                is_mobile:'',
                img_upload_title : '',
                img_upload: '',
                tam_img_width: 0,
                dropzoneOptions: {
                    url: '#',
                    thumbnailWidth: 150,
                    maxFilesize: 50,
                    maxFiles: 1,
                    headers: { "My-Awesome-Header": "header value" }
                },
                imagen : '',
                canales : [],
                delegaciones : [],
                url_descarga : '',
                video_elemento_seleccionado: null,
                grabacion_selected : null,
            }
        },
        methods: {

            IrVideo()
            {
                if(this.evento && this.evento.video_id)
                {
                    this.$router.push({path: '/video/' + this.evento.video_id + ''});
                }
            },
            DescargarGrabacion()
            {
                console.log('aaa');
                window.open(this.url_descarga, "_blank");
            },
            SubirGrabacion()
            {
                console.log('subiendo video');
              ApiService.subirGrabacion(this.grabacion_selected.id).then( r => {
                  console.log(r);
                  this.$swal.fire({
                      title: 'Grabación convertida en vídeo con éxito',
                      text: 'Ha sido añadido a la cola de codificación y será notificado por telegram cuando haya finalizado',
                      type: 'success',
                      showCancelButton: false,
                  });
                  this.$router.push({path: '/admin/videos'});
              }).catch(function(){


                  console.log('FAILURE!!');

              }) ;
            },
            customFormatter(date) {
                return moment(date).format('YYYY-MM-DD');
            },
            SeleccionarGrabacion(item,index,event)
            {
                console.log('seleccionando grabacion');
                this.url_descarga = 'https://agustinostv-front.archive.tornadocore.es/v2/index.php/grabaciones/reproducir?id='+item.id+ '&token='+ localStorage.getItem('token');
                this.video_elemento_seleccionado =  item.video_id;

                this.grabacion_selected = item;


            },
            UpdateEvento()
            {

            },
            DeleteEvento()
            {


            },

            VolverPanel()
            {
                this.$router.push({path: '/admin'});
            },
            IrEventos()
            {
                this.$router.push({path: '/admin/eventos'});
            },


            ObtenerFechadeEvento()
            {


            },
            ReloadData()
            {
                ApiService.getCanales(null).then( r => {
                   this.canales = r.data.data;
                   console.log('canales');
                   console.log(this.canales);
                });

                ApiService.getDelegaciones(null,'DESC',1000).then( r => {
                    this.delegaciones = r.data.data
                    console.log('delegaciones');
                    console.log(this.delegaciones);
                })

                ApiService.getEvento(this.$route.params.id).then( r => {
                    this.evento = r.data;

                })


            },

        },
        mounted(){
            ApiService.getMe().then(r => {
                if(r.data.role != 'ROOT' && r.data.role != 'ADMIN' && r.data.role != 'SUPERVISOR')
                {
                    this.$swal.fire({
                        title: 'Acceso no autorizado',
                        text: 'No lo intentes, de aquí en adelante no tienes acceso',
                        type: 'error',
                        showCancelButton: false,
                    });
                    this.$router.push({path: '/'});
                }
                else {
                    this.show = true;
                }

            });

        },
        created(){

            this.tam_img_width = window.innerWidth * 0.40;
            ApiService.isMobile().then(response => {
                console.log('isMobile');
                console.log(response);
                this.is_mobile = response.data.is_mobile;
                if(localStorage.getItem('is_mobile'))
                {

                    this.is_mobile = localStorage.getItem('is_mobile');
                    console.log('leyendo is_mobile : ' + this.is_mobile );
                }

                else {
                    console.log('asignando is_mobile = ' + this.is_mobile);
                    localStorage.setItem('is_mobile', this.is_mobile);
                    if(this.is_mobile == true) {
                        localStorage.setItem('res_video', '360p');
                    }
                    else {
                        localStorage.setItem('res_video', '720p');
                    }
                    this.$forceUpdate();
                }
            });

            this.ReloadData();
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>
